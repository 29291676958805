import {Card, CardBody, CardText, CardTitle} from '@gs-ux-uitoolkit-react/card';
import {Col, Row} from '@gs-ux-uitoolkit-react/layout';
import {ClaimButton} from '../../Buttons/ClaimButton';
import React, {useEffect, useState} from 'react';
import {ApproveModal} from '../../Approve/ApproveModal';
import {useQuery} from "@apollo/client";
import {GET_REQUEST_DETAILS} from "../../../constants/GraphqlCallDefinitions";
import {RequestTitle} from "./RequestTitle";
import {isClaimed, isInEndState} from "../../../services/utils/QueueService";
import {UnclaimButton} from "../../Buttons/UnclaimButton";
import {currentUserVar} from "../../../constants/cache";

export const RequestHeader = () => {
    const [isRequestClaimed, setIsRequestClaimed] = useState(false);
    const {data} = useQuery(GET_REQUEST_DETAILS);

    const isRequestUnclaimedOrInClaimedStateByOtherUser = () => {
        return !isRequestClaimed || (data?.getIRCRequest?.claimedBy !== currentUserVar() && isClaimed(data?.getIRCRequest));
    }

    const isRequestInClaimedStateBySelf = () => {
        return isRequestClaimed && data?.getIRCRequest?.claimedBy === currentUserVar() && isClaimed(data?.getIRCRequest);
    }

    useEffect(() => {
        if (data?.getIRCRequest?.claimedBy?.length) {
            setIsRequestClaimed(true);
        } else {
            setIsRequestClaimed(false);
        }
    }, [data?.getIRCRequest?.claimedBy]);

    return (
        <section>
            <Card elevation="00" style={{backgroundColor: 'inherit'}}>
                <CardBody>
                    <CardTitle>
                        <RequestTitle data={data?.getIRCRequest}/>
                    </CardTitle>
                    <CardText>
                        <Row>
                            <Col>
                                <Row style={{gap: '5px'}}>
                                    {!isInEndState(data?.getIRCRequest) && isRequestInClaimedStateBySelf() &&
                                        <UnclaimButton/>
                                    }
                                    {!isInEndState(data?.getIRCRequest) && isRequestUnclaimedOrInClaimedStateByOtherUser() &&
                                        <ClaimButton claimedBy={data?.getIRCRequest?.claimedBy}/>
                                    }
                                    {isRequestClaimed &&
                                        <ApproveModal/>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </CardText>
                </CardBody>
            </Card>
        </section>
    );
}
