import {Button} from '@gs-ux-uitoolkit-react/button';
import {useMutation} from "@apollo/client";
import {UPDATE_CLAIMED_BY} from "../../constants/GraphqlCallDefinitions";
import {showToast} from "../Notification/NotificationService";
import React, {useEffect, useState} from "react";
import {ButtonStatus} from "@gs-ux-uitoolkit-common/button/dist/button/button-props";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {reportToEUM} from "../../services/EUMService";
import {currentRequestIdVar} from "../../constants/cache";

export const UnclaimButton = () => {
    const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('none');
    const [updateStatus, {
        loading, error
    }] = useMutation(UPDATE_CLAIMED_BY, { variables: { updatedAttributes: { claimedBy: null} } });
    const defaultUnclaimText = 'Release Request';

    const onClick = () => {
        setButtonStatus('loading');
        reportToEUM(`Clicked to unclaim request ${currentRequestIdVar()}`, { requestId: currentRequestIdVar() });
        updateStatus();
    };

    useEffect(() => {
        if (error) {
            setButtonStatus('error');
            showToast({
                status: 'error',
                message: `Unable to unclaim request. If the error continues, please reach out to Publication Support.`,
                autoDismiss: 15000,
            });
        } else if (buttonStatus === 'loading' && !loading) {
            setButtonStatus('success');
        }
    }, [loading, error]);

    return (
        <>
            <Button
                actionType='primary'
                onClick={onClick}
                status={buttonStatus}
            >
                {
                    error ? <Icon name="refresh" type="filled" /> : undefined
                }
                {defaultUnclaimText}
            </Button>
        </>
    )
}