import React, {useEffect, useState} from 'react';
import {Checkbox, CheckboxChangeEvent} from '@gs-ux-uitoolkit-react/checkbox';
import {Heading} from '@gs-ux-uitoolkit-react/text';
import {Button} from '@gs-ux-uitoolkit-react/button';
import {useQuery} from "@apollo/client";
import {GET_APPROVAL_REQUIREMENTS, GET_REQUEST_DETAILS} from "../../constants/GraphqlCallDefinitions";
import {Alert} from "@gs-ux-uitoolkit-react/alert";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {wizardStyleSheet} from "../../constants/StyleSheets";
import {getDisplayName} from "../../services/utils/DisplayNameService";

interface ApprovalsContentProps {
    currentApprovers: string[];
    isDisabled: boolean;
    setCurrentApprovers: (approvers: string[]) => void;
    approversWarningMessage: string | undefined;

}

export const ApprovalsContent = ({
                                     currentApprovers,
                                     setCurrentApprovers,
                                     isDisabled,
                                     approversWarningMessage
                                 }: ApprovalsContentProps) => {
    const [willApproveOBO, setWillApproveOBO] = useState(false);
    const {data} = useQuery(GET_REQUEST_DETAILS);
    const {minimumNumberApprovers, eligibleApprovers, peopleMetadata} = useQuery(GET_APPROVAL_REQUIREMENTS).data?.getApprovalRequirements || {};
    const cssClasses = useStyleSheet(wizardStyleSheet, null);

    const onChange = ({name, checkedState}: CheckboxChangeEvent) => {
        if (checkedState && name) {
            setCurrentApprovers([...currentApprovers, name])
        } else {
            setCurrentApprovers(currentApprovers.filter(approver => approver !== name))
        }
    };

    const isMemberPreviousApprover = (previousApprovers: string[], approver: string) => {
        return previousApprovers ? previousApprovers?.includes(approver) : false
    };

    const PotentialApprovers = eligibleApprovers?.map((approver: string, index: number) => {
        const previousApprovers: string[] = data?.getIRCRequest?.approvedBy;
        const hasPreviouslyApproved = isMemberPreviousApprover(previousApprovers, approver);
        if (approver != data?.currentUser) {
            return <Checkbox key={index} name={approver} onChange={onChange}
                             defaultChecked={currentApprovers.includes(approver)}
                             disabled={isDisabled || hasPreviouslyApproved}>
                {getDisplayName(approver, peopleMetadata)}
            </Checkbox>
        }
    })

    const hasCurrentUserApproved = isMemberPreviousApprover(currentApprovers, data?.currentUser);
    return (
        <div>
            <Heading typography='heading05' className={"approvalHeading"}>
                Approver(s) [{currentApprovers.length} of {minimumNumberApprovers} required]
            </Heading>
            <Checkbox checked={hasCurrentUserApproved} onChange={onChange} name={data?.currentUser}
                      disabled={isDisabled}>
                {getDisplayName(data?.currentUser, peopleMetadata)}
            </Checkbox>
            {!willApproveOBO &&
                <Button className='linkFormatting' onClick={() => setWillApproveOBO(true)}>Approve on behalf of
                    others</Button>
            }
            {willApproveOBO &&
                <div>
                    <Heading typography='heading05' className={"approvalHeading"}>
                        Approve on behalf of others
                    </Heading>
                    {PotentialApprovers}
                </div>
            }
            {
                approversWarningMessage &&
                <Alert className={cssClasses.alert}
                       status="warning"
                       emphasis="subtle"
                >
                    {approversWarningMessage}
                </Alert>
            }
        </div>
    )
}
